@import '../_common/utils';

.Sponsors {
  padding-bottom: 100px;

  .SponsorsTitleContainer {
    text-align: center;
  }

  .SponsorsLogoList {
    margin: 40px 0 40px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
  }

  .SponsorItem {
    margin: 20px;
    vertical-align: middle;
    opacity: 0.8;


    &.-gold {
      margin: 30px;
    }

    &:hover {
      opacity: 1;
    }
  }

  .SponsorImage {
    vertical-align: middle;

    &.-gold {
      width: 300px;
      height: 180px;
    }

    &.-silver {
      width: 220px;
      height: 110px;
    }

    &.-partner {
      width: 250px;
      height: 100px;
    }

    &.-servicetitan {
      width: 260px;
      height: 160px;
    }

    &.-gg {
      height: 60px;
      width: 170px;
    }
  }

  // .GoldSponsors {
  //   width: 300px;
  // }

  // .SilverSponsors, .PartnersFriendsSponsors {
  //   width: 240px;
  // }


  .IllustrationContainer {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Illustration {
    position: relative;
    width: 75%;
  }


  @include media-down(md) {
    .Illustration {
      width: 50%;
    }
  }
}
