@import '../_common/utils';

.TimelineItem {
  display: flex;
  width: 100px;
  justify-content: center;
  align-items: flex-start;
  font-weight: bold;
  color: rgb(0, 222, 211);
  padding-top: 21px;
  overflow: hidden;
  font-size: 1.1rem;

  @include media-down(md) {
    width: 80px;
    font-size: 1rem;
  }

  .TimeValue {
    position: relative;

    .TimePattern {
      width: 20px;
      height: 300px;
      position: absolute;
      top: 100%;
      margin-left: -10px;
      margin-top: 10px;
      left: 50%;
      background-image: url(/timeline-pattern.png);
      background-repeat: repeat-y;
      background-position-x: center;
      background-position-y: top;
    }


    &:before,
    &:after {
      display: block;
      width: 100%;
      content: " ";
      position: absolute;
      height: 1px;
      background: #c3c8e5;
      top: 50%;
      margin-top: -1px;
      opacity: .3;
    }

    &:before {
      right: 100%;
      margin-right: 7px;
    }

    &:after {
      left: 100%;
      margin-left: 7px;
    }
  }

  &.-orient-left .TimeValue:after {
    display: none;
  }

  &.-orient-right .TimeValue:before {
    display: none;
  }
}
