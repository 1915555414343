@import '~_common/utils';

.Title {
  font-size: 3.25rem;
  font-family: Rajdhani;
  color: #FFFFFF;
  margin-bottom: 2rem;
  z-index: 10;

  @include media-down(lg) {
    font-size: 2.75rem;
  }

  &.-size-small {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    line-height: 2.2rem;

    @include media-down(lg) {
      font-size: 1.5rem;
    }
  }
}
