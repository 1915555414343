.Header {
  padding: 15px 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  transition: all .25s ease;

  &.-is-sticked {
    position: fixed;
    background-color: rgba(6, 14, 78, 0.918);
  }

  .container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  }

  .Logo {
    width: 75px;
    margin-right: 10px;
    text-align: center;
    z-index: 1111;

    .LogoImg {
      width: 60px;
    }
  }

  .MobileActionButton {
    display: none;
  }

  .Navigation {

    // width: calc(100% - 120px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;

    .Link {
      font-size: .9em;
      display: block;
      text-decoration: none;
      color: white;
      text-transform: uppercase;
      font-weight: bold;
      padding: 4px 0;
      margin: 0 1em;
      border-bottom: 2px solid transparent;
      transition: all .25s ease;
      cursor: pointer;

      &.active, &:hover {
        border-color: #fff;
      }
    }
  }

  .ActionButton {
    margin-left: 1em;
  }

  .NavBtn {
    display: none;
    color: white;
    right: 0;
    margin: 10px 10px;
    font-size: 30px;
  }


  @media (min-width:1024px) {
    .Logo {
      width: 100px;
      margin-right: 20px;
    }
  }

  @media (max-width: 600px) {

    padding: 5px 0;

    .container {
      // padding: 0;
    }

    .DesktopActionButton {
      display: none;
    }

    .MobileActionButton {
      display: block;
    }

    .Navigation {
      justify-content: center;
      flex-direction: column;
      top: -500px;
      transition: top .3s ease;
      position: absolute;
      background-color: #060e4eea;
      width: 100%;
      padding-top: 25px;
      z-index: -1;
    }

    &.-open .Navigation {
      top: 66px;
      left: 0;
      transition: top .3s ease;
    }

    .NavBtn {
      display: block;
    }

    .Logo {
      width: 40px;
      height: 40px;
      margin-right: 0;

      .LogoImg {
        width: 40px;
      }
    }
  }

}


