@import '../_common/utils';

.About {
  position: relative;
  display: flex;
  justify-content: center;
  margin: auto;
  text-align: initial;
  color: white;
  padding-top: 100px;
  padding-bottom: 100px;

  .IllustrationContainer {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Illustration {
    position: relative;
    width: 100%;
  }

  .CodeOfConductLink {
    color: rgb(217, 29, 189);
  }

  @include media-down(md) {
    .Illustration {
      width: 60%;
    }
  }
}



