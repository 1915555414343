.Location {
  margin: 150px auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;

  .title {
    font-size: 3em;
  }

  .Map {
    margin: 50px 0;
    width: 80vw;
    height: calc(80vw / 1.7);
    max-width: 960px;
    max-height: 540px;
    position: relative;
    z-index: 10;
  }
  .Splash {
    position: absolute;
    user-select: none;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 1350px;
    z-index: 1;
    transition: 0.1s;
  }

  .Marker {
    color: #fff;
    position: relative;
    text-align: center;
    left: -80px;
    top: 20px;
    width: 150px;
    transition: all .3s ease;
    font-size: 1rem;
    line-height: 2rem;
    text-align: center;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    transition: all .3s ease;

    .MarkerIllustration {
      margin: 0 auto;
      width: 100px;
      height: 100px;
      margin-bottom: 10px;
      // border-radius: 50%;
      background-image: url(https://onsen.io/images/design_2016/image_rocket.png);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }

    .MarkerText {
      text-align: center;
      white-space: nowrap;
      padding: 3px 15px;
      background: #d91dbd;
      font-weight: bold;
      // transition: all .3s ease;
      border-radius: 3px;
    }

    &:hover {
      .MarkerText {
        background: #00ded3;
      }
    }
  }

  @media only screen and (max-width: 1350px) {
    .Splash {
      width: 100vw;
      left: -50px;
    }
  }
  @media only screen and (max-width: 500px) {
    overflow: hidden;
    margin: 100px 0;
    .Map {
      margin: 30px 0;
      width: 90vw;
      height: calc(90vw / 1.4);
    }
    .Splash {
      width: 500px;
      left: -50px;
    }
  }
  @media only screen and (max-width: 500px) {
    .Splash {
      width: 120vw;
      left: -40px;
      top: 20px;
      height: 105vw;
    }
  }
}
