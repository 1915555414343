@import './_common/fonts.scss';
@import './_common/styles.scss';

* {
  margin:0;
	padding:0;
	outline: none;
	text-decoration: none;
  box-sizing: border-box;

  &:before, &:after {
    box-sizing: inherit;
  }
  &::-webkit-scrollbar-track {
		background-color: transparent;
	}
	&::-webkit-scrollbar {
		width: 3px;
		background-color: transparent;
	}
	&::-webkit-scrollbar-thumb {
		border-radius: 10px;
		box-shadow: inset 0 0 6px #C3C8E5;
		-webkit-box-shadow: inset 0 0 6px #C3C8E5;
		background-color: #FF790B;
	}
}

body {
  margin: 0;
  padding: 0;
  font-family: AktivGrotesk, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:#020937;
  color: #fff;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

