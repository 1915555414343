.Team {
  color: white;
  font-family: AktivGrotesk, sans-serif;
  text-align: center;

  .title {
    font-size: 3em;
  }

  .List {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
    .Member {
      width: 250px;
      position: relative;
      &:hover {
        .Splash {
          transform: scale(1);
        }
      }
      &:nth-child(2) {
        .Splash {
          background-position-y: -60px;
        }
      }
      .Splash {
        position: absolute;
        z-index: -1;
        width: 250px;
        height: 250px;
        transform: scale(0);
        top: 0;
        right: 0;
        left: 0;
        margin: auto;
        background-repeat: no-repeat;
        transition: 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
        background-position-x: center;
      }
      img {
        border-radius: 100%;
        object-fit: cover;
        margin-top: 50px;
        width: 170px;
        height: 170px;
        user-select: none;
      }
      .Name {
        font-size: 30px;
        line-height: 1.3;
        margin: 20px auto;
        width: 200px;
      }
      .Position {
        font-size: 12px;
        width: 200px;
        margin: auto;
        color: #c3c8e5;
      }
      @media screen and (max-width: 1200px) {
        width: 200px;
        margin: 0 5px;
        .Name,
        .Position {
          width: inherit;
        }

        img {
          width: 160px;
          height: 160px;
        }
      }
      @media screen and (max-width: 1050px) {
        width: 160px;
        &:hover {
          .Splash {
            transform: scale(0.8);
          }
        }
        .Splash {
          left: -20%;
        }
        img {
          width: 140px;
          height: 140px;
        }
      }
      @media screen and (max-width: 840px) {
        width: 140px;
        .Name {
          font-size: 25px;
        }
        .Splash {
          left: -35%;
          top: -5%;
        }
        img {
          width: 120px;
          height: 120px;
        }
      }
      @media screen and (max-width: 730px) {
        width: 120px;
        .Name {
          font-size: 20px;
          margin-top: 10px;
        }
        .Position {
          font-size: 11px;
        }
        &:hover {
          .Splash {
            transform: scale(0.7);
          }
        }
        .Splash {
          left: -45%;
          top: -10%;
        }
        img {
          width: 100px;
          height: 100px;
        }
      }
      @media screen and (max-width: 630px) {
        width: 100%;
        margin-bottom: 20px;
        .Name {
          font-size: 30px;
        }
        .Position {
          width: 67%;
          font-size: 14px;
        }
        &:hover {
          .Splash {
            transform: scale(1);
          }
        }
        .Splash {
          left: 0;
          top: 0;
        }
        img {
          width: 180px;
          height: 180px;
        }
      }
    }
    @media screen and (max-width: 630px) {
      flex-direction: column;
      align-items: center;
    }
  }
}
