@import '~_common/utils';

.Description {
  color: #c3c8e5;
  font-size: 1.5rem;
  letter-spacing: 1px;
  line-height: 2.25rem;
  margin-bottom: 2rem;

  @include media-down(lg) {
    font-size: 1.2rem;
  }
}
