@import '../_common/utils';

.ScheduleItem {
  width: 100%;
  color: #c3c8e5;
  padding-bottom: 30px;
  // transition: all .25s ease;
  border-radius: 30px;

  &:hover {
    // background-color: #00ded307;
  }

  .ScheduleItemSpeakers {
    flex-direction: row;
    display: flex;
    width: 100%;
    min-height: 60px;
    align-items: center;
    margin-bottom: 10px;
  }

  .ItemSpeakerLink {
    cursor: pointer;
  }

  .ItemSpeakerLink:hover .ItemSpeaker {
    // opacity: 1;
  }


  .ItemSpeaker {
    font-size: 1.3rem;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: all .25s ease;
    // opacity: .7;

    @include media-down(md) {
      font-size: 1.1rem;
    }
  }

  .SpeakerImg {
    width: 60px;
    height: 60px;
    flex-shrink: 0;
    border-radius: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    // border: 4px solid #c3c8e5;
  }

  .ItemLanguage {
    flex: 1;
    font-size: 18px;
    color: #fff;
  }

  .ItemTopic {
    flex: 9;
    padding-left: 5px;
    padding-right: 5px;
  }

  .ItemTitle {
    font-size: 1.05rem;
    min-height: 60px;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    // border: 4px dashed #ff790b;
    border-radius: 22px;
    font-weight: bold;
    background-position: center;
    background-size: 100% auto;
    background-repeat: no-repeat;
    position: relative;

    &.-level-0 {
    }

    &.-level-1 {
    }

    &.-level-2 {
    }

    &.-level-3 {
    }

    .ItemTitleBackground {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
    }

    @include media-down(md) {
      font-size: 1rem;
    }

    &.-large {
      justify-content: center;
      min-height: 60px;
    }

    &.-orient-left {
      flex-direction: row-reverse;
    }
  }

  &.-placeholder {
    visibility: hidden;
  }

  &.-general {
    justify-content: center;

    .ItemTitle {
      justify-content: center;
      font-size: 1.5rem;
    }
  }

  &.-break {
    color: #2b3460;

    .ItemTitle {
      font-size: 1.5rem;
      justify-content: center;
      // background-image: url('/frames/for_breaks.png');
    }
  }

  &.-orient-right:not(.-break):not(.-general) {
    .ItemTitle,
    .ScheduleItemSpeakers {
      justify-content: flex-end;
      text-align: right;
    }

    .SpeakerImg {
      margin-left: 15px;
    }

    .ItemSpeaker {
      margin-left: 25px;
    }

    @include media-down(md) {
      .SpeakerImg {
        margin-left: 7px;
      }

      .ItemSpeaker {
        margin-left: 10px;
      }
    }
  }


  &.-orient-left:not(.-break):not(.-general) {
    .ItemTitle,
    .ScheduleItemSpeakers {
      justify-content: flex-start;
      text-align: left;
    }

    .SpeakerImg {
      margin-right: 15px;
      order: -1;
    }

    .ItemSpeaker {
      margin-right: 25px;
    }

    @include media-down(md) {
      .SpeakerImg {
        margin-right: 7px;
      }

      .ItemSpeaker {
        margin-right: 10px;
      }
    }
  }
}
