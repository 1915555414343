@import '../_common/utils';

.Speakers {

  padding-bottom: 100px;

  .SpeakersTitleContainer {
    text-align: center;
  }

  .SpeakersApplicationContainer {
    text-align: right;
  }

  .IllustrationContainer {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Illustration {
    position: relative;
    width: 70%;
  }

  @include media-down(md) {
    text-align: left;

    .Illustration {
      width: 50%;
    }
  }
}
