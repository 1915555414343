@font-face {
  font-family: "Rajdhani";
  src: url(/static/media/Rajdhani-Regular.89be8bb7.eot);
  src: local("Rajdhani"), local("Rajdhani-Regular"), url(/static/media/Rajdhani-Regular.89be8bb7.eot?#iefix) format("embedded-opentype"), url(/static/media/Rajdhani-Regular.f281a6f0.woff2) format("woff2"), url(/static/media/Rajdhani-Regular.fd76eb0d.woff) format("woff"), url(/static/media/Rajdhani-Regular.375bed51.ttf) format("truetype"), url(/static/media/Rajdhani-Regular.77ecefd0.svg#Rajdhani-Regular) format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Rajdhani";
  src: url(/static/media/Rajdhani-Light.5e8a4ac2.eot);
  src: local("Rajdhani Light"), local("Rajdhani-Light"), url(/static/media/Rajdhani-Light.5e8a4ac2.eot?#iefix) format("embedded-opentype"), url(/static/media/Rajdhani-Light.88bb815a.woff2) format("woff2"), url(/static/media/Rajdhani-Light.93df4bb2.woff) format("woff"), url(/static/media/Rajdhani-Light.35ba2fa4.ttf) format("truetype"), url(/static/media/Rajdhani-Light.9c0b8125.svg#Rajdhani-Light) format("svg");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: "Rajdhani";
  src: url(/static/media/Rajdhani-Bold.961af6f1.eot);
  src: local("Rajdhani Bold"), local("Rajdhani-Bold"), url(/static/media/Rajdhani-Bold.961af6f1.eot?#iefix) format("embedded-opentype"), url(/static/media/Rajdhani-Bold.7581dddd.woff2) format("woff2"), url(/static/media/Rajdhani-Bold.5f104ea8.woff) format("woff"), url(/static/media/Rajdhani-Bold.9d93e3b7.ttf) format("truetype"), url(/static/media/Rajdhani-Bold.133d3046.svg#Rajdhani-Bold) format("svg");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: "Rajdhani";
  src: url(/static/media/Rajdhani-Medium.35af7e3d.eot);
  src: local("Rajdhani Medium"), local("Rajdhani-Medium"), url(/static/media/Rajdhani-Medium.35af7e3d.eot?#iefix) format("embedded-opentype"), url(/static/media/Rajdhani-Medium.78e4ac3f.woff2) format("woff2"), url(/static/media/Rajdhani-Medium.c5ba150f.woff) format("woff"), url(/static/media/Rajdhani-Medium.47aca29c.ttf) format("truetype"), url(/static/media/Rajdhani-Medium.4b15b709.svg#Rajdhani-Medium) format("svg");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: "Rajdhani";
  src: url(/static/media/Rajdhani-SemiBold.237266a1.eot);
  src: local("Rajdhani SemiBold"), local("Rajdhani-SemiBold"), url(/static/media/Rajdhani-SemiBold.237266a1.eot?#iefix) format("embedded-opentype"), url(/static/media/Rajdhani-SemiBold.dbb5dfb9.woff2) format("woff2"), url(/static/media/Rajdhani-SemiBold.1bd53389.woff) format("woff"), url(/static/media/Rajdhani-SemiBold.314ca407.ttf) format("truetype"), url(/static/media/Rajdhani-SemiBold.2e89e624.svg#Rajdhani-SemiBold) format("svg");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: "AktivGrotesk-XBoldItalic";
  src: url(/static/media/AktivGrotesk-XBoldItalic.544b2013.eot);
  src: local("Aktiv Grotesk XBold Italic"), local("AktivGrotesk-XBoldItalic"), url(/static/media/AktivGrotesk-XBoldItalic.544b2013.eot?#iefix) format("embedded-opentype"), url(/static/media/AktivGrotesk-XBoldItalic.f0395d10.woff2) format("woff2"), url(/static/media/AktivGrotesk-XBoldItalic.54ef7f39.woff) format("woff"), url(/static/media/AktivGrotesk-XBoldItalic.cc9101bd.ttf) format("truetype"), url(/static/media/AktivGrotesk-XBoldItalic.1f6f36c4.svg#AktivGrotesk-XBoldItalic) format("svg");
  font-weight: bold;
  font-style: italic; }

@font-face {
  font-family: "AktivGrotesk";
  src: url(/static/media/AktivGrotesk-ThinItalic.3af18e06.eot);
  src: local("Aktiv Grotesk Thin Italic"), local("AktivGrotesk-ThinItalic"), url(/static/media/AktivGrotesk-ThinItalic.3af18e06.eot?#iefix) format("embedded-opentype"), url(/static/media/AktivGrotesk-ThinItalic.ffb45c87.woff2) format("woff2"), url(/static/media/AktivGrotesk-ThinItalic.c126e68a.woff) format("woff"), url(/static/media/AktivGrotesk-ThinItalic.bb65147b.ttf) format("truetype"), url(/static/media/AktivGrotesk-ThinItalic.7c1926ec.svg#AktivGrotesk-ThinItalic) format("svg");
  font-weight: 100;
  font-style: italic; }

@font-face {
  font-family: "AktivGrotesk";
  src: url(/static/media/AktivGrotesk-BoldItalic.3cc25b09.eot);
  src: local("Aktiv Grotesk Bold Italic"), local("AktivGrotesk-BoldItalic"), url(/static/media/AktivGrotesk-BoldItalic.3cc25b09.eot?#iefix) format("embedded-opentype"), url(/static/media/AktivGrotesk-BoldItalic.9df980be.woff2) format("woff2"), url(/static/media/AktivGrotesk-BoldItalic.232200bb.woff) format("woff"), url(/static/media/AktivGrotesk-BoldItalic.b8c4f19d.ttf) format("truetype"), url(/static/media/AktivGrotesk-BoldItalic.9466c4dc.svg#AktivGrotesk-BoldItalic) format("svg");
  font-weight: bold;
  font-style: italic; }

@font-face {
  font-family: "AktivGrotesk";
  src: url(/static/media/AktivGrotesk-Italic.30ef61ad.eot);
  src: local("Aktiv Grotesk Italic"), local("AktivGrotesk-Italic"), url(/static/media/AktivGrotesk-Italic.30ef61ad.eot?#iefix) format("embedded-opentype"), url(/static/media/AktivGrotesk-Italic.3f2eda3f.woff2) format("woff2"), url(/static/media/AktivGrotesk-Italic.36899f4c.woff) format("woff"), url(/static/media/AktivGrotesk-Italic.acbe1dc2.ttf) format("truetype"), url(/static/media/AktivGrotesk-Italic.520bb258.svg#AktivGrotesk-Italic) format("svg");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: "AktivGrotesk-Hairline";
  src: url(/static/media/AktivGrotesk-Hairline.9ac4f041.eot);
  src: local("Aktiv Grotesk Hairline"), local("AktivGrotesk-Hairline"), url(/static/media/AktivGrotesk-Hairline.9ac4f041.eot?#iefix) format("embedded-opentype"), url(/static/media/AktivGrotesk-Hairline.526dc2c5.woff2) format("woff2"), url(/static/media/AktivGrotesk-Hairline.2f13a86b.woff) format("woff"), url(/static/media/AktivGrotesk-Hairline.fc89f1ff.ttf) format("truetype"), url(/static/media/AktivGrotesk-Hairline.b8be4086.svg#AktivGrotesk-Hairline) format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "AktivGrotesk";
  src: url(/static/media/AktivGrotesk-LightItalic.f4aa4880.eot);
  src: local("Aktiv Grotesk Light Italic"), local("AktivGrotesk-LightItalic"), url(/static/media/AktivGrotesk-LightItalic.f4aa4880.eot?#iefix) format("embedded-opentype"), url(/static/media/AktivGrotesk-LightItalic.e1cac81d.woff2) format("woff2"), url(/static/media/AktivGrotesk-LightItalic.5fcbe725.woff) format("woff"), url(/static/media/AktivGrotesk-LightItalic.11f45dca.ttf) format("truetype"), url(/static/media/AktivGrotesk-LightItalic.38268293.svg#AktivGrotesk-LightItalic) format("svg");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: "AktivGrotesk-HairlineItalic";
  src: url(/static/media/AktivGrotesk-HairlineItalic.60cfc5fb.eot);
  src: local("Aktiv Grotesk Hairline Italic"), local("AktivGrotesk-HairlineItalic"), url(/static/media/AktivGrotesk-HairlineItalic.60cfc5fb.eot?#iefix) format("embedded-opentype"), url(/static/media/AktivGrotesk-HairlineItalic.57b7f1ce.woff2) format("woff2"), url(/static/media/AktivGrotesk-HairlineItalic.8d85f957.woff) format("woff"), url(/static/media/AktivGrotesk-HairlineItalic.7a81cb2f.ttf) format("truetype"), url(/static/media/AktivGrotesk-HairlineItalic.303441d3.svg#AktivGrotesk-HairlineItalic) format("svg");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: "AktivGrotesk";
  src: url(/static/media/AktivGrotesk-Bold.b227d5c7.eot);
  src: local("Aktiv Grotesk Bold"), local("AktivGrotesk-Bold"), url(/static/media/AktivGrotesk-Bold.b227d5c7.eot?#iefix) format("embedded-opentype"), url(/static/media/AktivGrotesk-Bold.724493d6.woff2) format("woff2"), url(/static/media/AktivGrotesk-Bold.b48400b4.woff) format("woff"), url(/static/media/AktivGrotesk-Bold.05ce968b.ttf) format("truetype"), url(/static/media/AktivGrotesk-Bold.6fb80d75.svg#AktivGrotesk-Bold) format("svg");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: "AktivGrotesk";
  src: url(/static/media/AktivGrotesk-MediumItalic.edb59894.eot);
  src: local("Aktiv Grotesk Medium Italic"), local("AktivGrotesk-MediumItalic"), url(/static/media/AktivGrotesk-MediumItalic.edb59894.eot?#iefix) format("embedded-opentype"), url(/static/media/AktivGrotesk-MediumItalic.8bd24703.woff2) format("woff2"), url(/static/media/AktivGrotesk-MediumItalic.eb6b4ba3.woff) format("woff"), url(/static/media/AktivGrotesk-MediumItalic.b7d86945.ttf) format("truetype"), url(/static/media/AktivGrotesk-MediumItalic.cd6adc35.svg#AktivGrotesk-MediumItalic) format("svg");
  font-weight: 500;
  font-style: italic; }

@font-face {
  font-family: "AktivGrotesk";
  src: url(/static/media/AktivGrotesk-Medium.0c6739b9.eot);
  src: local("Aktiv Grotesk Medium"), local("AktivGrotesk-Medium"), url(/static/media/AktivGrotesk-Medium.0c6739b9.eot?#iefix) format("embedded-opentype"), url(/static/media/AktivGrotesk-Medium.5873d863.woff2) format("woff2"), url(/static/media/AktivGrotesk-Medium.91c5daf2.woff) format("woff"), url(/static/media/AktivGrotesk-Medium.6c9138fa.ttf) format("truetype"), url(/static/media/AktivGrotesk-Medium.5e5869d4.svg#AktivGrotesk-Medium) format("svg");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: "AktivGrotesk";
  src: url(/static/media/AktivGrotesk-Light.3329cae9.eot);
  src: local("Aktiv Grotesk Light"), local("AktivGrotesk-Light"), url(/static/media/AktivGrotesk-Light.3329cae9.eot?#iefix) format("embedded-opentype"), url(/static/media/AktivGrotesk-Light.9492c3a4.woff2) format("woff2"), url(/static/media/AktivGrotesk-Light.d4eaf7c4.woff) format("woff"), url(/static/media/AktivGrotesk-Light.6a0d5a4c.ttf) format("truetype"), url(/static/media/AktivGrotesk-Light.11cd4956.svg#AktivGrotesk-Light) format("svg");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: "AktivGrotesk";
  src: url(/static/media/AktivGrotesk-BlackItalic.1b62dcef.eot);
  src: local("Aktiv Grotesk Black Italic"), local("AktivGrotesk-BlackItalic"), url(/static/media/AktivGrotesk-BlackItalic.1b62dcef.eot?#iefix) format("embedded-opentype"), url(/static/media/AktivGrotesk-BlackItalic.bd38c8f3.woff2) format("woff2"), url(/static/media/AktivGrotesk-BlackItalic.80fdfb68.woff) format("woff"), url(/static/media/AktivGrotesk-BlackItalic.d707e133.ttf) format("truetype"), url(/static/media/AktivGrotesk-BlackItalic.5b1120c8.svg#AktivGrotesk-BlackItalic) format("svg");
  font-weight: 900;
  font-style: italic; }

@font-face {
  font-family: "AktivGrotesk-XBold";
  src: url(/static/media/AktivGrotesk-XBold.d00b039c.eot);
  src: local("Aktiv Grotesk XBold"), local("AktivGrotesk-XBold"), url(/static/media/AktivGrotesk-XBold.d00b039c.eot?#iefix) format("embedded-opentype"), url(/static/media/AktivGrotesk-XBold.47f00d57.woff2) format("woff2"), url(/static/media/AktivGrotesk-XBold.0c397df1.woff) format("woff"), url(/static/media/AktivGrotesk-XBold.6315b88e.ttf) format("truetype"), url(/static/media/AktivGrotesk-XBold.4e063b88.svg#AktivGrotesk-XBold) format("svg");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: "AktivGrotesk";
  src: url(/static/media/AktivGrotesk-Thin.da47f03d.eot);
  src: local("Aktiv Grotesk Thin"), local("AktivGrotesk-Thin"), url(/static/media/AktivGrotesk-Thin.da47f03d.eot?#iefix) format("embedded-opentype"), url(/static/media/AktivGrotesk-Thin.556adcdf.woff2) format("woff2"), url(/static/media/AktivGrotesk-Thin.b39b04a5.woff) format("woff"), url(/static/media/AktivGrotesk-Thin.8c6faee0.ttf) format("truetype"), url(/static/media/AktivGrotesk-Thin.271c6d70.svg#AktivGrotesk-Thin) format("svg");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: "AktivGrotesk";
  src: url(/static/media/AktivGrotesk-Regular.79b123d5.eot);
  src: local("Aktiv Grotesk"), local("AktivGrotesk-Regular"), url(/static/media/AktivGrotesk-Regular.79b123d5.eot?#iefix) format("embedded-opentype"), url(/static/media/AktivGrotesk-Regular.879c841e.woff2) format("woff2"), url(/static/media/AktivGrotesk-Regular.ce70caa7.woff) format("woff"), url(/static/media/AktivGrotesk-Regular.b44bcadd.ttf) format("truetype"), url(/static/media/AktivGrotesk-Regular.ec56b4b2.svg#AktivGrotesk-Regular) format("svg");
  font-weight: normal;
  font-style: normal; }

.-text-muted {
  color: #c3c8e5; }

* {
  margin: 0;
  padding: 0;
  outline: none;
  text-decoration: none;
  box-sizing: border-box; }
  *:before, *:after {
    box-sizing: inherit; }
  *::-webkit-scrollbar-track {
    background-color: transparent; }
  *::-webkit-scrollbar {
    width: 3px;
    background-color: transparent; }
  *::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px #C3C8E5;
    -webkit-box-shadow: inset 0 0 6px #C3C8E5;
    background-color: #FF790B; }

body {
  margin: 0;
  padding: 0;
  font-family: AktivGrotesk, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #020937;
  color: #fff;
  font-size: 16px; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.Title {
  font-size: 3.25rem;
  font-family: Rajdhani;
  color: #FFFFFF;
  margin-bottom: 2rem;
  z-index: 10; }
  @media (max-width: 1199.98px) {
    .Title {
      font-size: 2.75rem; } }
  .Title.-size-small {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    line-height: 2.2rem; }
    @media (max-width: 1199.98px) {
      .Title.-size-small {
        font-size: 1.5rem; } }

.Description {
  color: #c3c8e5;
  font-size: 1.5rem;
  letter-spacing: 1px;
  line-height: 2.25rem;
  margin-bottom: 2rem; }
  @media (max-width: 1199.98px) {
    .Description {
      font-size: 1.2rem; } }

.About {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  margin: auto;
  text-align: left;
  text-align: initial;
  color: white;
  padding-top: 100px;
  padding-bottom: 100px; }
  .About .IllustrationContainer {
    width: 100%;
    height: 100%;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center; }
  .About .Illustration {
    position: relative;
    width: 100%; }
  .About .CodeOfConductLink {
    color: #d91dbd; }
  @media (max-width: 991.98px) {
    .About .Illustration {
      width: 60%; } }

.SectionBox {
  max-width: 80%;
  padding: 2rem 0;
  margin: 0 auto; }

.Button {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-transform: uppercase;
  vertical-align: top;
  font-weight: bold;
  white-space: nowrap;
  padding: 1rem;
  border: none;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  background-color: transparent;
  cursor: pointer;
  background-size: contain;
  background-position-y: center;
  background-repeat: round no-repeat;
  color: black;
  text-align: center;
  margin-right: 0px; }
  .Button.-size-default {
    font-size: 1.3rem;
    width: 18rem;
    height: 4.5rem; }
  .Button.-size-small {
    font-size: 0.8em;
    height: 2.5rem;
    width: 10rem;
    background-position-y: -3px; }
  .Button.-color-pink {
    background-image: url("/button-bckg-pink.png"); }
  .Button.-color-orange {
    background-image: url("/button-bckg-orange.png"); }
  .Button:hover {
    background-image: url("/button-bckg-blue.png");
    border-bottom-color: transparent; }
  @media (max-width: 600px) {
    .Button.-size-small {
      background-position-y: 0px; } }

.Location {
  margin: 150px auto;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  position: relative; }
  .Location .title {
    font-size: 3em; }
  .Location .Map {
    margin: 50px 0;
    width: 80vw;
    height: calc(80vw / 1.7);
    max-width: 960px;
    max-height: 540px;
    position: relative;
    z-index: 10; }
  .Location .Splash {
    position: absolute;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 1350px;
    z-index: 1;
    -webkit-transition: 0.1s;
    transition: 0.1s; }
  .Location .Marker {
    color: #fff;
    position: relative;
    text-align: center;
    left: -80px;
    top: 20px;
    width: 150px;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    font-size: 1rem;
    line-height: 2rem;
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    transition: all .3s ease; }
    .Location .Marker .MarkerIllustration {
      margin: 0 auto;
      width: 100px;
      height: 100px;
      margin-bottom: 10px;
      background-image: url(https://onsen.io/images/design_2016/image_rocket.png);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat; }
    .Location .Marker .MarkerText {
      text-align: center;
      white-space: nowrap;
      padding: 3px 15px;
      background: #d91dbd;
      font-weight: bold;
      border-radius: 3px; }
    .Location .Marker:hover .MarkerText {
      background: #00ded3; }
  @media only screen and (max-width: 1350px) {
    .Location .Splash {
      width: 100vw;
      left: -50px; } }
  @media only screen and (max-width: 500px) {
    .Location {
      overflow: hidden;
      margin: 100px 0; }
      .Location .Map {
        margin: 30px 0;
        width: 90vw;
        height: calc(90vw / 1.4); }
      .Location .Splash {
        width: 500px;
        left: -50px; } }
  @media only screen and (max-width: 500px) {
    .Location .Splash {
      width: 120vw;
      left: -40px;
      top: 20px;
      height: 105vw; } }

.Team {
  color: white;
  font-family: AktivGrotesk, sans-serif;
  text-align: center; }
  .Team .title {
    font-size: 3em; }
  .Team .List {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    list-style: none;
    padding: 0; }
    .Team .List .Member {
      width: 250px;
      position: relative; }
      .Team .List .Member:hover .Splash {
        -webkit-transform: scale(1);
                transform: scale(1); }
      .Team .List .Member:nth-child(2) .Splash {
        background-position-y: -60px; }
      .Team .List .Member .Splash {
        position: absolute;
        z-index: -1;
        width: 250px;
        height: 250px;
        -webkit-transform: scale(0);
                transform: scale(0);
        top: 0;
        right: 0;
        left: 0;
        margin: auto;
        background-repeat: no-repeat;
        -webkit-transition: 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
        transition: 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
        background-position-x: center; }
      .Team .List .Member img {
        border-radius: 100%;
        object-fit: cover;
        margin-top: 50px;
        width: 170px;
        height: 170px;
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none; }
      .Team .List .Member .Name {
        font-size: 30px;
        line-height: 1.3;
        margin: 20px auto;
        width: 200px; }
      .Team .List .Member .Position {
        font-size: 12px;
        width: 200px;
        margin: auto;
        color: #c3c8e5; }
      @media screen and (max-width: 1200px) {
        .Team .List .Member {
          width: 200px;
          margin: 0 5px; }
          .Team .List .Member .Name,
          .Team .List .Member .Position {
            width: inherit; }
          .Team .List .Member img {
            width: 160px;
            height: 160px; } }
      @media screen and (max-width: 1050px) {
        .Team .List .Member {
          width: 160px; }
          .Team .List .Member:hover .Splash {
            -webkit-transform: scale(0.8);
                    transform: scale(0.8); }
          .Team .List .Member .Splash {
            left: -20%; }
          .Team .List .Member img {
            width: 140px;
            height: 140px; } }
      @media screen and (max-width: 840px) {
        .Team .List .Member {
          width: 140px; }
          .Team .List .Member .Name {
            font-size: 25px; }
          .Team .List .Member .Splash {
            left: -35%;
            top: -5%; }
          .Team .List .Member img {
            width: 120px;
            height: 120px; } }
      @media screen and (max-width: 730px) {
        .Team .List .Member {
          width: 120px; }
          .Team .List .Member .Name {
            font-size: 20px;
            margin-top: 10px; }
          .Team .List .Member .Position {
            font-size: 11px; }
          .Team .List .Member:hover .Splash {
            -webkit-transform: scale(0.7);
                    transform: scale(0.7); }
          .Team .List .Member .Splash {
            left: -45%;
            top: -10%; }
          .Team .List .Member img {
            width: 100px;
            height: 100px; } }
      @media screen and (max-width: 630px) {
        .Team .List .Member {
          width: 100%;
          margin-bottom: 20px; }
          .Team .List .Member .Name {
            font-size: 30px; }
          .Team .List .Member .Position {
            width: 67%;
            font-size: 14px; }
          .Team .List .Member:hover .Splash {
            -webkit-transform: scale(1);
                    transform: scale(1); }
          .Team .List .Member .Splash {
            left: 0;
            top: 0; }
          .Team .List .Member img {
            width: 180px;
            height: 180px; } }
    @media screen and (max-width: 630px) {
      .Team .List {
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: center;
                align-items: center; } }

.Header {
  padding: 15px 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  -webkit-transition: all .25s ease;
  transition: all .25s ease; }
  .Header.-is-sticked {
    position: fixed;
    background-color: rgba(6, 14, 78, 0.918); }
  .Header .container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center; }
  .Header .Logo {
    width: 75px;
    margin-right: 10px;
    text-align: center;
    z-index: 1111; }
    .Header .Logo .LogoImg {
      width: 60px; }
  .Header .MobileActionButton {
    display: none; }
  .Header .Navigation {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap; }
    .Header .Navigation .Link {
      font-size: .9em;
      display: block;
      text-decoration: none;
      color: white;
      text-transform: uppercase;
      font-weight: bold;
      padding: 4px 0;
      margin: 0 1em;
      border-bottom: 2px solid transparent;
      -webkit-transition: all .25s ease;
      transition: all .25s ease;
      cursor: pointer; }
      .Header .Navigation .Link.active, .Header .Navigation .Link:hover {
        border-color: #fff; }
  .Header .ActionButton {
    margin-left: 1em; }
  .Header .NavBtn {
    display: none;
    color: white;
    right: 0;
    margin: 10px 10px;
    font-size: 30px; }
  @media (min-width: 1024px) {
    .Header .Logo {
      width: 100px;
      margin-right: 20px; } }
  @media (max-width: 600px) {
    .Header {
      padding: 5px 0; }
      .Header .DesktopActionButton {
        display: none; }
      .Header .MobileActionButton {
        display: block; }
      .Header .Navigation {
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-flex-direction: column;
                flex-direction: column;
        top: -500px;
        -webkit-transition: top .3s ease;
        transition: top .3s ease;
        position: absolute;
        background-color: #060e4eea;
        width: 100%;
        padding-top: 25px;
        z-index: -1; }
      .Header.-open .Navigation {
        top: 66px;
        left: 0;
        -webkit-transition: top .3s ease;
        transition: top .3s ease; }
      .Header .NavBtn {
        display: block; }
      .Header .Logo {
        width: 40px;
        height: 40px;
        margin-right: 0; }
        .Header .Logo .LogoImg {
          width: 40px; } }

.Intro {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  min-height: 100vh;
  padding-top: 27vh;
  position: relative;
  font-family: Rajdhani; }
  .Intro .Info {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between; }
  .Intro:before {
    display: block;
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(/cityscape.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: bottom;
    background-position-x: center;
    z-index: -1;
    opacity: .85; }

.Intro p {
  color: #00ded3;
  font-weight: bold; }

.Intro h1 {
  margin: 0px;
  color: #ffffff;
  font-weight: bold; }

@media (min-width: 450px) {
  .Intro p {
    font-size: 1.5em; }
  .Intro h1 {
    font-size: 3em; } }

@media (min-width: 650px) {
  .Intro p {
    font-size: 1.5em; }
  .Intro h1 {
    font-size: 4em; } }

.Footer {
  background-image: url("/planet-react.png");
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: 25px;
  background-size: cover;
  color: white;
  height: 1000px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  font-family: AktivGrotesk, sans-serif; }
  .Footer .Title {
    font-size: 20px;
    width: 200px;
    margin: auto;
    margin: 0 auto 50px auto;
    text-align: center;
    font-size: 2em; }
  .Footer .Link {
    color: #00ded3;
    text-decoration: underline; }
  .Footer .Icons {
    list-style: none;
    padding: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin-bottom: 30px; }
    .Footer .Icons .Icon {
      margin: 0 15px; }
      .Footer .Icons .Icon svg {
        fill: white;
        -webkit-transition: 0.2s;
        transition: 0.2s;
        width: 35px;
        height: 35px; }
      .Footer .Icons .Icon:hover svg {
        fill: #d91dbd; }
  .Footer .PressRelease {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 1.2em; }
    .Footer .PressRelease .Link {
      text-decoration: underline;
      margin: 0 5px;
      font-size: 1.1em; }
  .Footer .Copyright {
    font-size: 0.9em;
    text-align: center; }
    .Footer .Copyright p {
      margin: 10px 20px 30px; }
    .Footer .Copyright .Link {
      margin-left: 5px; }
      .Footer .Copyright .Link:nth-child(3) {
        margin-right: 5px; }
  @media only screen and (max-width: 560px) {
    .Footer {
      background-position-y: 100%;
      background-size: 1200px;
      height: 757px; }
      .Footer .Title {
        font-size: 32px; }
      .Footer .Icon svg {
        width: 30px;
        height: 30px; }
      .Footer .Copyright p {
        font-size: 14px; } }
  @media only screen and (max-width: 330px) {
    .Footer {
      background-position-y: 100%;
      background-size: 1000px;
      height: 630px; }
      .Footer .Title {
        font-size: 30px; }
      .Footer .Icon svg {
        width: 20px;
        height: 20px; }
      .Footer .Copyright p {
        margin: 10px 25px 30px; } }

.FAQ {
  margin: 100px 0;
}

.FAQ .title {
  font-size: 3em;
  text-align: center;
  margin-bottom: 100px;
}

.FAQ p {
  line-height: 1.5;
  margin: 15px 0 30px 0;
  color: #c3c8e5;
}

.FAQ a {
  color: #D91DBD;
  text-decoration: underline;
}

.Link:hover {
  cursor: pointer;
}

.Speakers {
  padding-bottom: 100px; }
  .Speakers .SpeakersTitleContainer {
    text-align: center; }
  .Speakers .SpeakersApplicationContainer {
    text-align: right; }
  .Speakers .IllustrationContainer {
    width: 100%;
    height: 100%;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center; }
  .Speakers .Illustration {
    position: relative;
    width: 70%; }
  @media (max-width: 991.98px) {
    .Speakers {
      text-align: left; }
      .Speakers .Illustration {
        width: 50%; } }

.SpeakerCard {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin-bottom: 90px; }
  @media (max-width: 575.98px) {
    .SpeakerCard {
      -webkit-flex-direction: column;
              flex-direction: column;
      text-align: center; } }
  .SpeakerCard .SpeakerImgContainer {
    -webkit-flex: 0 1;
            flex: 0 1;
    width: 220px;
    margin: 0 auto;
    position: relative; }
  .SpeakerCard .SpeakerImageOverlay {
    background: #020937;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 220px;
    height: 220px;
    border-radius: 50%;
    opacity: 0.3;
    -webkit-transition: .3s ease;
    transition: .3s ease; }
  .SpeakerCard .SpeakerInfoContainer {
    -webkit-flex: 1 1;
            flex: 1 1;
    padding: 0 30px; }
  .SpeakerCard .SpeakerImg {
    width: 220px;
    height: 220px;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover; }
  .SpeakerCard .SpeakerName {
    margin-bottom: 15px;
    line-height: 1.2em; }
  .SpeakerCard .SpeakerPosition {
    font-size: 0.7em;
    letter-spacing: 1px;
    margin-bottom: 20px; }
  .SpeakerCard .SpeakerTopic {
    font-size: 0.875em;
    line-height: 1.4em;
    color: white;
    background-color: rgba(255, 255, 255, 0.178);
    padding: .4em .6em;
    border-radius: 2px;
    margin-bottom: 20px; }
  .SpeakerCard:hover .SpeakerImageOverlay {
    opacity: 0; }
  .SpeakerCard .SpeakerSocialLinks {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row; }
    @media (max-width: 575.98px) {
      .SpeakerCard .SpeakerSocialLinks {
        -webkit-justify-content: center;
                justify-content: center; } }

.SpeakerSocialLink {
  display: block;
  margin-right: 15px; }
  .SpeakerSocialLink .SpeakerSocialLinkIcon {
    width: 50px;
    height: 50px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat; }
  .SpeakerSocialLink.-github .SpeakerSocialLinkIcon {
    background-image: url(/social-icons/GitHub.png); }
  .SpeakerSocialLink.-github:hover .SpeakerSocialLinkIcon {
    background-image: url(/social-icons/GitHub-hover.png); }
  .SpeakerSocialLink.-twitter .SpeakerSocialLinkIcon {
    background-image: url(/social-icons/Twitter.png); }
  .SpeakerSocialLink.-twitter:hover .SpeakerSocialLinkIcon {
    background-image: url(/social-icons/Twitter-hover.png); }

.Sponsors {
  padding-bottom: 100px; }
  .Sponsors .SponsorsTitleContainer {
    text-align: center; }
  .Sponsors .SponsorsLogoList {
    margin: 40px 0 40px 0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: center;
            align-items: center; }
  .Sponsors .SponsorItem {
    margin: 20px;
    vertical-align: middle;
    opacity: 0.8; }
    .Sponsors .SponsorItem.-gold {
      margin: 30px; }
    .Sponsors .SponsorItem:hover {
      opacity: 1; }
  .Sponsors .SponsorImage {
    vertical-align: middle; }
    .Sponsors .SponsorImage.-gold {
      width: 300px;
      height: 180px; }
    .Sponsors .SponsorImage.-silver {
      width: 220px;
      height: 110px; }
    .Sponsors .SponsorImage.-partner {
      width: 250px;
      height: 100px; }
    .Sponsors .SponsorImage.-servicetitan {
      width: 260px;
      height: 160px; }
    .Sponsors .SponsorImage.-gg {
      height: 60px;
      width: 170px; }
  .Sponsors .IllustrationContainer {
    width: 100%;
    height: 100%;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center; }
  .Sponsors .Illustration {
    position: relative;
    width: 75%; }
  @media (max-width: 991.98px) {
    .Sponsors .Illustration {
      width: 50%; } }

.Schedule {
  padding-bottom: 100px; }
  .Schedule .ScheduleTitleContainer {
    text-align: center;
    margin-bottom: 4rem; }
    .Schedule .ScheduleTitleContainer .Title {
      margin-bottom: .5rem; }
  .Schedule .ScheduleLevels {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    margin-bottom: 3rem;
    font-weight: bold;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap; }
    .Schedule .ScheduleLevels .Level {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-flex-direction: row;
              flex-direction: row;
      margin: 0 3%; }
    .Schedule .ScheduleLevels .LevelIcon {
      margin-right: 10px; }
  .Schedule .ScheduleWrapper {
    overflow-x: auto; }
  .Schedule .ScheduleBody {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    min-width: 720px; }
  .Schedule .ScheduleLocation {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-flex: 1 1;
            flex: 1 1;
    -webkit-flex-shrink: 1;
            flex-shrink: 1;
    -webkit-flex-grow: 1;
            flex-grow: 1; }
    .Schedule .ScheduleLocation .Title {
      font-size: 2rem; }
    .Schedule .ScheduleLocation.-orient-right {
      text-align: right; }
    .Schedule .ScheduleLocation.-orient-left {
      text-align: left; }
  .Schedule .ScheduleTimeline {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; }
    .Schedule .ScheduleTimeline .Title {
      font-size: 2rem; }
  @media (max-width: 991.98px) {
    .Schedule .container {
      padding: 0;
      max-width: 740px; } }
  @media (max-width: 991.98px) {
    .Schedule .ScheduleBody {
      padding: 0 15px; } }

.TimelineItem {
  display: -webkit-flex;
  display: flex;
  width: 100px;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  font-weight: bold;
  color: #00ded3;
  padding-top: 21px;
  overflow: hidden;
  font-size: 1.1rem; }
  @media (max-width: 991.98px) {
    .TimelineItem {
      width: 80px;
      font-size: 1rem; } }
  .TimelineItem .TimeValue {
    position: relative; }
    .TimelineItem .TimeValue .TimePattern {
      width: 20px;
      height: 300px;
      position: absolute;
      top: 100%;
      margin-left: -10px;
      margin-top: 10px;
      left: 50%;
      background-image: url(/timeline-pattern.png);
      background-repeat: repeat-y;
      background-position-x: center;
      background-position-y: top; }
    .TimelineItem .TimeValue:before, .TimelineItem .TimeValue:after {
      display: block;
      width: 100%;
      content: " ";
      position: absolute;
      height: 1px;
      background: #c3c8e5;
      top: 50%;
      margin-top: -1px;
      opacity: .3; }
    .TimelineItem .TimeValue:before {
      right: 100%;
      margin-right: 7px; }
    .TimelineItem .TimeValue:after {
      left: 100%;
      margin-left: 7px; }
  .TimelineItem.-orient-left .TimeValue:after {
    display: none; }
  .TimelineItem.-orient-right .TimeValue:before {
    display: none; }

.ScheduleItem {
  width: 100%;
  color: #c3c8e5;
  padding-bottom: 30px;
  border-radius: 30px; }
  .ScheduleItem .ScheduleItemSpeakers {
    -webkit-flex-direction: row;
            flex-direction: row;
    display: -webkit-flex;
    display: flex;
    width: 100%;
    min-height: 60px;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 10px; }
  .ScheduleItem .ItemSpeakerLink {
    cursor: pointer; }
  .ScheduleItem .ItemSpeaker {
    font-size: 1.3rem;
    font-weight: bold;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-transition: all .25s ease;
    transition: all .25s ease; }
    @media (max-width: 991.98px) {
      .ScheduleItem .ItemSpeaker {
        font-size: 1.1rem; } }
  .ScheduleItem .SpeakerImg {
    width: 60px;
    height: 60px;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    border-radius: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; }
  .ScheduleItem .ItemLanguage {
    -webkit-flex: 1 1;
            flex: 1 1;
    font-size: 18px;
    color: #fff; }
  .ScheduleItem .ItemTopic {
    -webkit-flex: 9 1;
            flex: 9 1;
    padding-left: 5px;
    padding-right: 5px; }
  .ScheduleItem .ItemTitle {
    font-size: 1.05rem;
    min-height: 60px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    width: 100%;
    -webkit-align-items: center;
            align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 22px;
    font-weight: bold;
    background-position: center;
    background-size: 100% auto;
    background-repeat: no-repeat;
    position: relative; }
    .ScheduleItem .ItemTitle .ItemTitleBackground {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      pointer-events: none; }
    @media (max-width: 991.98px) {
      .ScheduleItem .ItemTitle {
        font-size: 1rem; } }
    .ScheduleItem .ItemTitle.-large {
      -webkit-justify-content: center;
              justify-content: center;
      min-height: 60px; }
    .ScheduleItem .ItemTitle.-orient-left {
      -webkit-flex-direction: row-reverse;
              flex-direction: row-reverse; }
  .ScheduleItem.-placeholder {
    visibility: hidden; }
  .ScheduleItem.-general {
    -webkit-justify-content: center;
            justify-content: center; }
    .ScheduleItem.-general .ItemTitle {
      -webkit-justify-content: center;
              justify-content: center;
      font-size: 1.5rem; }
  .ScheduleItem.-break {
    color: #2b3460; }
    .ScheduleItem.-break .ItemTitle {
      font-size: 1.5rem;
      -webkit-justify-content: center;
              justify-content: center; }
  .ScheduleItem.-orient-right:not(.-break):not(.-general) .ItemTitle,
  .ScheduleItem.-orient-right:not(.-break):not(.-general) .ScheduleItemSpeakers {
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    text-align: right; }
  .ScheduleItem.-orient-right:not(.-break):not(.-general) .SpeakerImg {
    margin-left: 15px; }
  .ScheduleItem.-orient-right:not(.-break):not(.-general) .ItemSpeaker {
    margin-left: 25px; }
  @media (max-width: 991.98px) {
    .ScheduleItem.-orient-right:not(.-break):not(.-general) .SpeakerImg {
      margin-left: 7px; }
    .ScheduleItem.-orient-right:not(.-break):not(.-general) .ItemSpeaker {
      margin-left: 10px; } }
  .ScheduleItem.-orient-left:not(.-break):not(.-general) .ItemTitle,
  .ScheduleItem.-orient-left:not(.-break):not(.-general) .ScheduleItemSpeakers {
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    text-align: left; }
  .ScheduleItem.-orient-left:not(.-break):not(.-general) .SpeakerImg {
    margin-right: 15px;
    -webkit-order: -1;
            order: -1; }
  .ScheduleItem.-orient-left:not(.-break):not(.-general) .ItemSpeaker {
    margin-right: 25px; }
  @media (max-width: 991.98px) {
    .ScheduleItem.-orient-left:not(.-break):not(.-general) .SpeakerImg {
      margin-right: 7px; }
    .ScheduleItem.-orient-left:not(.-break):not(.-general) .ItemSpeaker {
      margin-right: 10px; } }

.Job {
  font-family: AktivGrotesk;
  cursor: pointer; }
  .Job.active .Job-Title, .Job:hover .Job-Title {
    color: #00DED3; }
  .Job.active .Job-Company, .Job:hover .Job-Company {
    color: #D91DBD; }

.Job-Title {
  font-weight: 700;
  font-size: 24px;
  color: #ffffff;
  margin: 0; }

.Job-Company {
  font-weight: 500;
  font-size: 16px;
  color: #c3c8e6;
  margin: 0; }

.BigTitle {
  text-align: center; }

@media only screen and (max-width: 500px) {
  .JobList {
    text-align: center; } }

.BackgroundIllustration {
  position: absolute;
  top: 100vh;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background-image: url("/background-pattern.png");
  background-position-x: center;
  background-size: 95%;
  background-repeat: repeat-y;
  opacity: .4;
  -webkit-transform: translateY(0);
          transform: translateY(0); }

.App {
  position: relative;
  overflow: hidden; }
  .App .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
    height: 40vmin; }
  .App .App-link {
    color: #61dafb; }

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

